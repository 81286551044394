const templates = [
    {
        'id': 0,
        'template': '当{{subspace}}时，若我们比较 {{focus1}} 和 {{focus2}} 在 {{agg}} {{measure}} 上的差异，它们相差 {{parameter}}.',
    },
    {
        'id': 1,
        'template': '当{{subspace}}时， {{focus1} }和 {{focus2}} 在 {{agg}} {{measure}} 上的差值为 {{parameter}}.',
    },
    {
        'id': 2,
        'template': '当{{subspace}}时，如果比较 {{agg}} {{measure}}， {{focus1}} 比 {{focus2}}要 {{高/低}} {{parameter}}.',
    }
]

export default templates;