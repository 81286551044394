export default {
    // Data
    UPLOAD_DATA: 'UPLOAD_DATA',
    CONNECT_DATA: 'CONNECT_DATA',
    CHANGE_DATA: 'CHANGE_DATA',
    VISUALIZE_DATA: 'VISUALIZE_DATA',
    UPDATE_DATA_URL: 'UPDATE_DATA_URL',
    // Fact
    ADD_FACT: 'ADD_FACT',
    INSERT_FACT: 'INSERT_FACT',
    UPDATE_FACT: 'UPDATE_FACT',
    DELETE_FACT: 'DELETE_FACT',
    DELETE_UNUSEDFACT: 'DELETE_UNUSEDFACT',
    ORDER_FACTS: 'ORDER_FACTS',
    SET_HOVER_INDEX: 'SET_HOVER_INDEX',
    UPDATE_FILTERS:'UPDATE_FILTERS',
    UPDATE_FILTERS_TITLE:'UPDATE_FILTERS_TITLE',
    UPDATE_DATARES:'UPDATE_DATARES',
    UPDATE_SCHEMA_FILTER:'UPDATE_SCHEMA_FILTER',
    UPDATE_FILTERS_CHANGE_FLAG:'UPDATE_FILTERS_CHANGE_FLAG',
    CHANGE_CURRENT_EDITING:'CHANGE_CURRENT_EDITING',
    UPDATE_FILTERS_LOCAL: 'UPDATE_FILTERS_LOCAL', 
    UPDATE_PANNEL_WIDTH:'UPDATE_PANNEL_WIDTH',

    // Story
    INIT_FACT: 'INIT_FACT',
    INIT_RELATION: 'INIT_RELATION',
    SELECT_FACT: 'SELECT_FACT',
    CHANGE_METHOD: 'CHANGE_METHOD',
    SET_STORY_PARAMETER: 'SET_STORY_PARAMETER',
    SET_AGGREGATION_LEVEL: 'SET_AGGREGATION_LEVEL',
    SET_REWARD_WEIGHT: 'SET_REWARD_WEIGHT',
    GENERATE_STORY: 'GENERATE_STORY',
    CHANGE_TITLE: 'CHANGE_TITLE',
    CHANGE_SUBTITLE: 'CHANGE_SUBTITLE',
    UPDATE_STORY_CARD_NUMBER: 'UPDATE_STORY_CARD_NUMBER',
    UPDATE_COMMENT_STORY_INFO: 'UPDATE_COMMENT_STORY_INFO',
    UPDATE_UUID: 'UPDATE_UUID',
    UPDATE_PROGRESS: 'UPDATE_PROGRESS',//生成进度
    EXPORT_PDF: 'EXPORT_PDF',//导出pdf
    //user
    UPDATE_USER_INFO: 'UPDATE_USER_INFO',
    UPDATE_USER_OPERATION: 'UPDATE_USER_OPERATION',
    UPDATE_CONVERT_TYPE: 'UPDATE_CONVERT_TYPE',
    UPDATE_LOCALE: 'UPDATE_LOCALE'
}